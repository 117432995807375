<template>
    
    <div id="page">
        
        <b-container>
        
            <h2>CopyScadenza playground</h2>
            <p>Strumento per il test dei copy della scadenza dei vouchers</p>
            Documentazione: <a target="_blank" href="https://trello.com/c/vysJA840/882-copy-scadenza">https://trello.com/c/vysJA840/882-copy-scadenza</a>
            <!-- developed and testd on cloud9 -->
            <hr>
        
            <section>
                <h3>default</h3>
                <input v-model="defaultInput" placeholder="edit me" size="100">
                <br/><br/>
                <CopyScadenza
                    v-bind:default="this.defaultInput"
                    v-bind:testVouchersValidity="this.testVouchersValidity.default"
                />
            </section>
            
            <hr>
            
            <section>
                <h3>promo mesi</h3>
                <input v-model="promoMonthsInput" placeholder="edit me" size="100">
                <br/><br/>
                <CopyScadenza
                    v-bind:promoMonths="this.promoMonthsInput"
                    v-bind:testVouchersValidity="this.testVouchersValidity.promoMonths"
                />
            </section>
            
            <hr>
            
            <section>
                <h3>promo data fissa</h3>
                <input v-model="promoFixedInput" placeholder="edit me" size="100">
                <br/><br/>
                <CopyScadenza
                    v-bind:promoFixed="this.promoFixedInput"
                    v-bind:testVouchersValidity="this.testVouchersValidity.promoFixed"
                />
            </section>
            
        </b-container>
        
    </div>
    
</template>

<script>

import CopyScadenza from '@/components/blocks/common/copy-scadenza'

export default {
    name: 'copy-scadenza-playground',
    components: {
      CopyScadenza,  
    },
    data() {
        return {
            
            // copy di esempio
            defaultInput: 'Aquista oggi, avrai ben {value} per scegliere dove e quando viaggiare!',
            promoMonthsInput: 'Promozione "{promoTitle}", se acquisti entro il {promoValidUntil}, avrai ben {value} mesi anzichè i tradizionali {defaultMonths} per decidere dove e quando viaggiare',
            promoFixedInput: 'Speciale {promoTitle}, acquista adesso e prolungheremo la durata del tuo voucher fino al {value} (invece dei tradizionali {defaultMonths} mesi)',
            
            // config.guiSettings.vouchersValidity di prova per i 3 casi da testare
            testVouchersValidity: {
            
                // nessuna promo attiva
                default: {
                    isPromo: false,
                    defaultMonths: '12',
                    type: 'months',
                    promoTitle: '',
                    promoValidUntil: '',
                    value: 12,
                },
                
                // promo "a mesi" attiva
                promoMonths: {
                    isPromo: true,
                    defaultMonths: '12',
                    type: 'months',
                    promoTitle: 'PROMO 16 MESI',
                    promoValidUntil: '31-12-2021',
                    value: 16,
                },
                
                // promo "data scadenza fissa" attiva
                promoFixed: {
                    isPromo: true,
                    defaultMonths: '12',
                    type: 'fixed',
                    promoTitle: 'PROMO 16 MESI',
                    promoValidUntil: '31-12-2021',
                    value: '30-04-2022',
                },
            
            },
        }
    },
}
</script>