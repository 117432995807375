var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "page" } },
    [
      _c("b-container", [
        _c("h2", [_vm._v("CopyScadenza playground")]),
        _c("p", [
          _vm._v("Strumento per il test dei copy della scadenza dei vouchers"),
        ]),
        _vm._v("\n        Documentazione: "),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: "https://trello.com/c/vysJA840/882-copy-scadenza",
            },
          },
          [_vm._v("https://trello.com/c/vysJA840/882-copy-scadenza")]
        ),
        _c("hr"),
        _c(
          "section",
          [
            _c("h3", [_vm._v("default")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.defaultInput,
                  expression: "defaultInput",
                },
              ],
              attrs: { placeholder: "edit me", size: "100" },
              domProps: { value: _vm.defaultInput },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.defaultInput = $event.target.value
                },
              },
            }),
            _c("br"),
            _c("br"),
            _c("CopyScadenza", {
              attrs: {
                default: this.defaultInput,
                testVouchersValidity: this.testVouchersValidity.default,
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "section",
          [
            _c("h3", [_vm._v("promo mesi")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.promoMonthsInput,
                  expression: "promoMonthsInput",
                },
              ],
              attrs: { placeholder: "edit me", size: "100" },
              domProps: { value: _vm.promoMonthsInput },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.promoMonthsInput = $event.target.value
                },
              },
            }),
            _c("br"),
            _c("br"),
            _c("CopyScadenza", {
              attrs: {
                promoMonths: this.promoMonthsInput,
                testVouchersValidity: this.testVouchersValidity.promoMonths,
              },
            }),
          ],
          1
        ),
        _c("hr"),
        _c(
          "section",
          [
            _c("h3", [_vm._v("promo data fissa")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.promoFixedInput,
                  expression: "promoFixedInput",
                },
              ],
              attrs: { placeholder: "edit me", size: "100" },
              domProps: { value: _vm.promoFixedInput },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.promoFixedInput = $event.target.value
                },
              },
            }),
            _c("br"),
            _c("br"),
            _c("CopyScadenza", {
              attrs: {
                promoFixed: this.promoFixedInput,
                testVouchersValidity: this.testVouchersValidity.promoFixed,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }